var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('titulo-seccion',[_c('template',{slot:"area-rutas"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Inicio")]),_vm._v(" / "),_c('router-link',{attrs:{"to":"/videos"}},[_vm._v("Videos")]),_vm._v(" / "),_c('router-link',{attrs:{"to":"/videos/playlists"}},[_vm._v("Playlists")]),_vm._v(" / "),_c('span',{staticClass:"active"},[_vm._v(_vm._s(_vm.titulo))])],1),_c('template',{slot:"area-titulo"},[_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.titulo))])])],2),_c('section',{staticClass:"noticias py-0 my-4"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row my-0"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"col-md-3 col-sm-2 col-xs-12 p-3"},[_c('div',{staticClass:"card card-video mb-4 h-100",style:({
              '--background-color-terciario':
                _vm.config.disenio.datos.color_terciario,
            })},[_c('div',{staticClass:"video-portada"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.thumbnail),expression:"item.thumbnail"},{name:"b-modal",rawName:"v-b-modal.modal-video",modifiers:{"modal-video":true}}],staticClass:"img-fluid img-responsive cursor md-round",attrs:{"alt":item.title,"title":item.title},on:{"click":function($event){return _vm.selectVideo(item)}}}),_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-video",modifiers:{"modal-video":true}}],staticClass:"btn btn-icon btn-play",style:({
                  '--background-color-btn':
                    _vm.config.disenio.datos.color_primario,
                }),on:{"click":function($event){return _vm.selectVideo(item)}}},[_c('i',{staticClass:"icon icon-white"},[_c('svg',{attrs:{"width":"20","height":"20","viewBox":"0 0 20 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 0V24L20 12L0 0Z","fill":"white"}})])])])]),_c('div',{staticClass:"card-body pt-3 pb-0 mb-0"},[_c('div',[_c('small',{staticClass:"text-start text-muted date py-0"},[_vm._v(_vm._s(item.published_at))])]),_c('p',{staticClass:"p-0 m-0"},[_c('strong',{staticClass:"text-secondary",style:({
                    '--color-secondary':
                      _vm.config.disenio.datos.color_secundario,
                    'text-transform': 'justify',
                  })},[_vm._v(_vm._s(item.title))])]),_c('p',{staticClass:"card-text text-truncate"},[_vm._v(_vm._s(item.description))])])])])}),0)])]),_c('b-modal',{ref:"my-modal",attrs:{"id":"modal-video","header-bg-variant":"dark","header-text-variant":"light","header-class":"text-small","title-class":"text-truncate","size":"lg","body-class":"p-0","centered":"","hide-footer":true},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h6',[_vm._v(_vm._s(_vm.title))])]},proxy:true}])},[(_vm.video)?_c('b-embed',{staticClass:"border",attrs:{"type":"iframe","allowfullscreen":true,"allow":"autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture","src":_vm.video}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }